<template>
  <div class="section">
    <h3>Name of Parties</h3>
    <div class="field" id="plaintiff">
      <p>
        Plaintiff Name &nbsp;
        <span class="clarification"> (What entity is filling this case) </span>
      </p>
      <input
        v-on:keydown="
          handleInputChange('plaintiffName', this.editablePlaintiffName)
        "
        v-model="this.editablePlaintiffName"
      />
    </div>
    <h1>V.</h1>
    <div class="defendants">
      <el-form-item
        v-for="defendant in editableDefendants"
        :key="defendant.key"
      >
        <div :class="'defendant'">
          <input
            v-on:keydown="handleDefendantChange('name', defendant)"
            placeholder="First Name"
            v-model="defendant.first_name"
          />
        </div>
        <div :class="'defendant'">
          <input
            v-on:keydown="handleDefendantChange('name', defendant)"
            placeholder="Last NAme"
            v-model="defendant.last_name"
          />
        </div>
        <div :class="'defendant'">
          <input
            v-on:keydown="handleDefendantChange('phone', defendant)"
            v-model="defendant.phone"
          />
        </div>
        <div :class="'defendant'">
          <input
            v-on:keydown="handleDefendantChange('email', defendant)"
            v-model="defendant.email"
          />
        </div>
      </el-form-item>
    </div>
    <div class="field">
      <p>All unknown occupants</p>
      <input
        v-on:keydown="
          handleInputChange(
            'allUnknownOccupants',
            this.editableallUnknownOccupants
          )
        "
        v-model="this.editableallUnknownOccupants"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NameOfPartiesModule',
    props: {
      plaintiffName: String,
      defendants: Array,
      allUnknownOccupants: String,
      handleDefendantChange: Function,
      handleInputChange: Function,
    },
    data() {
      return {
        editablePlaintiffName: this.plaintiffName,
        editableallUnknownOccupants: this.allUnknownOccupants,
        editableDefendants: this.defendants,
      }
    },
  }
</script>

<style scoped>
  .field {
    display: flex;
    flex-direction: column;
  }

  .defendants {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 10px;
  }

  .defendant {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    margin-right: 5px;
  }

  .defendant:last-child {
    margin-right: 0;
  }

  .defendant p {
    display: flex;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    margin-right: 5px;
    min-width: fit-content;
  }

  .field input,
  .defendants input {
    width: 100%;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
  }

  .field p {
    margin: 0 0 5px;
  }
</style>
