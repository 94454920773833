<template>
  <div class="section">
    <h3>Final Questions</h3>
    <div class="field final-questions" id="subsidized">
      <p>If tenant subsidized, select type:</p>
      <el-form-item>
        <el-select
          v-model="this.editableQuestion1"
          v-on:change="
            handleInputChange('tenantSubsidized', this.editableQuestion1)
          "
          class="m-2"
          placeholder="Select"
        >
          <el-option
            v-for="item in tenantOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="field final-questions" id="tenant">
      <p>Can we put tenant on a court ordered payment plan</p>
      <el-form-item>
        <el-select
          v-model="this.editableQuestion2"
          v-on:change="
            handleInputChange('tenantOnCourt', this.editableQuestion2)
          "
          class="m-2"
          placeholder="Select"
        >
          <el-option
            v-for="item in tenantOnCourtOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="field final-questions" id="maxLength">
      <p>{{ finalQuestion }}</p>
      <el-form-item>
        <el-select
          v-model="this.editableQuestion3"
          v-on:change="handleInputChange('maxLength', this.editableQuestion3)"
          class="m-2"
          placeholder="Select"
        >
          <el-option
            v-for="item in maxLengthOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="field final-questions" id="waive">
      <p>
        What amount can we waive if we get them out within 30 days after their
        first court date?
      </p>
      <el-form-item>
        <el-select
          v-model="this.editableQuestion4"
          v-on:change="handleInputChange('amountWaive', this.editableQuestion4)"
          class="m-2"
          placeholder="Select"
        >
          <el-option
            v-for="item in waiveOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="field final-questions">
      <p>Anything unique you want us to know about your case?</p>
      <el-form-item>
        <input
          v-on:keydown="
            handleInputChange('uniqueInformation', this.editableQuestion5)
          "
          v-model="this.editableQuestion5"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FinalQuestionModule',
    props: {
      question1: String,
      question2: String,
      question3: String,
      question4: String,
      question5: String,
      handleInputChange: Function,
    },
    data() {
      return {
        finalQuestion:
          'Max Length of Payment Plan Recommendation: <$4K, allow 12 months, $4K-6K 24 months, 6K+ 36 Months',
        editableQuestion1: this.question1,
        editableQuestion2: this.question2,
        editableQuestion3: this.question3,
        editableQuestion4: this.question4,
        editableQuestion5: this.question5,
      }
    },
    setup() {
      const tenantOptions = ['Tax Credit', 'CHA', 'HACC', 'Other']
      const tenantOnCourtOptions = ['YES', 'NO']
      const maxLengthOptions = ['36', '24', '12', 'NOT APPLICABLE']
      const waiveOptions = ['100%', '50%', '25%', '0%']
      return {
        maxLengthOptions,
        waiveOptions,
        tenantOnCourtOptions,
        tenantOptions,
      }
    },
  }
</script>

<style scoped>
  .field input {
    width: 100%;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
  }
  div.double div.input {
    width: 490px;
  }

  div.input {
    width: 100%;
  }
</style>
