<template>
  <div class="section">
    <h3>Rent and Balance</h3>
    <div class="field date">
      <el-form-item id="total">
        <p>Total Balance</p>
        <input
          v-on:keydown="
            handleInputChange('totalBalance', this.editableTotalBalance)
          "
          v-model="this.editableTotalBalance"
        />
      </el-form-item>
      <el-form-item id="through">
        <p>Balance Through (Select Year & Month)</p>
        <el-date-picker
          v-model="this.editableBalanceThrough"
          type="month"
          placeholder="Pick Month and Year"
        />
      </el-form-item>
    </div>
    <div class="field" id="monthly">
      <el-form-item>
        <p>
          Monthly Rent &nbsp;
          <span class="clarification">
            (Include Parking, etc) (Tenant portion only for subsidy tenants)
          </span>
        </p>
        <input
          v-on:keydown="
            handleInputChange('monthlyRent', this.editableMonthlyRent)
          "
          v-model="this.editableMonthlyRent"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RentAndBalanceModule',
    props: {
      totalBalance: String,
      balanceThrough: String,
      monthlyRent: String,
      handleInputChange: Function,
    },
    data() {
      return {
        editableTotalBalance: this.totalBalance,
        editableBalanceThrough: this.balanceThrough,
        editableMonthlyRent: this.monthlyRent,
      }
    },
  }
</script>

<style scoped>
  .field input {
    width: 100%;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
  }
  .el-date-editor.el-input {
    flex-basis: 50%;
  }
</style>
