<template>
  <div class="section">
    <h3>Address of defendant</h3>
    <div class="field medium nowrap" id="street">
      <el-form-item>
        <p>Address</p>
        <input
          v-on:keydown="handleInputChange('street', this.editableStreet)"
          v-model="this.editableStreet"
        />
      </el-form-item>
    </div>
    <div class="field medium nowrap">
      <el-form-item>
        <p>Unit/Suite</p>
        <input
          v-on:keydown="handleInputChange('unit', this.editableUnit)"
          v-model="this.editableUnit"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AddressOfDefendantModule',
    props: {
      zip: String,
      street: String,
      city: String,
      state: String,
      unit: String,
      handleInputChange: Function,
    },
    data() {
      return {
        editableZip: this.zip,
        editableStreet: this.street,
        editableCity: this.city,
        editableState: this.state,
        editableUnit: this.unit,
      }
    },
  }
</script>

<style scoped>
  .field input {
    width: 100%;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
  }
</style>
