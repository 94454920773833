<template>
  <div>
    <div :class="isFormLoading ? 'loader' : 'not-loader'">
      <pulse-loader
        :loading="isFormLoading"
        :color="loaderColor"
      ></pulse-loader>
    </div>
    <div v-if="showRejectionModal" class="rejection-modal">
      <div class="modal">
        <el-button v-on:click="rejectionModal" class="cross">X</el-button>
        <h3>Rejection reason</h3>
        <el-select
          v-model="rejectionOptionSelected"
          class="m-2"
          placeholder="Select the rejection option"
        >
          <el-option
            v-for="item in rejectionOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <div v-if="rejectionOptionSelected === 'Other'">
          <p>Describe the rejection reason:</p>
          <textarea v-model="rejectionMessage" type="textarea" />
        </div>
        <el-button v-on:click="rejectFormAction" type="danger"
          >Reject</el-button
        >
      </div>
    </div>
    <div v-if="!isFormLoading" class="center">
      <div class="content">
        <h1>Case Summary</h1>
        <form
          v-if="formInformation"
          class="container"
          encType="multipart/form-data"
        >
          <div style="margin-bottom: 10px">
            <a
              style="
                color: blue;
                font-weight: bold;
                margin-left: 10px;
                margin-bottom: 10px;
              "
              v-for="item in formInformation.files"
              :key="item"
              :href="item['url']"
              target="_blank"
            >
              {{ item['name'].toUpperCase() }}
            </a>
          </div>
          <NameOfPartiesModule
            :plaintiffName="formInformation.form.plaintiffName"
            :defendants="formInformation.form.defendants"
            :allUnknownOccupants="formInformation.form.allUnknownOccupants"
            :handleDefendantChange="handleDefendantChange"
            :handleInputChange="handleInputChange"
          />
          <AddressOfDefendantModule
            :zip="formInformation.form.zip"
            :street="formInformation.form.street"
            :city="formInformation.form.city"
            :state="formInformation.form.state"
            :unit="formInformation.form.unit"
            :handleInputChange="handleInputChange"
          />
          <RentAndBalanceModule
            :totalBalance="formInformation.form.totalBalance"
            :balanceThrough="formInformation.form.balanceThrough"
            :monthlyRent="formInformation.form.monthlyRent"
            :handleInputChange="handleInputChange"
          />
          <FinalQuestionsModule
            :question1="formInformation.form.tenantSubsidized"
            :question2="formInformation.form.tenantOnCourt"
            :question3="formInformation.form.maxLength"
            :question4="formInformation.form.amountWaive"
            :question5="formInformation.form.uniqueInformation"
            :handleInputChange="handleInputChange"
          />
          <div class="buttons">
            <a href="/admin"><el-button type="primary">Back</el-button></a>
            <div class="actions">
              <el-button v-on:click="rejectionModal" type="danger"
                >Reject</el-button
              >
              <el-button v-on:click="approveFormAction" type="success"
                >Approve</el-button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import NameOfPartiesModule from '@/components/molecules/NameOfPartiesModule'
  import AddressOfDefendantModule from '@/components/molecules/AddressOfDefendantModule'
  import RentAndBalanceModule from '@/components/molecules/RentAndBalanceModule'
  import FinalQuestionsModule from '@/components/molecules/FinalQuestionsModule'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { getForm, approveForm, rejectForm } from '../services/CloudFunctions'
  import { useRouter } from 'vue-router'

  export default {
    name: 'CaseSummary',
    components: {
      PulseLoader,
      NameOfPartiesModule,
      AddressOfDefendantModule,
      RentAndBalanceModule,
      FinalQuestionsModule,
    },
    beforeCreate() {
      var sessionHash = localStorage.getItem('sessionHash')
      var router = useRouter()
      if (!sessionHash) router.push('/')
    },
    data() {
      return {
        formId: location.pathname.split('/')[2],
        isFormLoading: true,
        loadingAction: false,
        showRejectionModal: false,
        formInformation: null,
        rejectionOptions: [
          'Rejected: Address incomplete or inaccurate',
          'Rejected: Missing affidavit information',
          'Other',
        ],
        rejectionOptionSelected: '',
        router: useRouter(),
        rejectionMessage: '',
      }
    },
    async mounted() {
      this.isFormLoading = true
      const form = await getForm(this.formId)
      this.formInformation = form.data
      console.log(this.formInformation)
      this.isFormLoading = false
    },
    methods: {
      async approveFormAction() {
        this.loadingAction = true
        await approveForm({
          id: this.formId,
          form: this.formInformation.form,
        })
        this.loadingAction = false
        this.router.push('/admin')
      },
      rejectionModal() {
        console.log(this.showRejectionModal)
        this.showRejectionModal = !this.showRejectionModal
      },
      async rejectFormAction() {
        this.loadingAction = true
        var reason = this.rejectionMessage
          ? this.rejectionMessage
          : this.rejectionOptionSelected
        this.formInformation.form.rejectionReason = reason
        await rejectForm({
          id: this.formId,
          form: this.formInformation.form,
        })
        this.loadingAction = false
        this.router.push('/admin')
      },
      handleDefendantChange(key, value) {
        var index = value.key - 1
        this.formInformation.form.defendants[index].key = value.key
      },
      handleInputChange(key, value) {
        this.formInformation.form[key] = value
      },
    },
  }
</script>

<style scoped>
  textarea {
    min-width: 400px;
    max-width: 400px;
    max-height: 600px;
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 7px 11px;
    height: 32px;
    border-radius: 8px;
    border: none;
    color: #606266;
    margin: 0 0 15px;
  }

  div.center {
    max-width: 850px;
  }

  div.rejection-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }

  div.modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 500px;
    padding: 50px;
    box-sizing: border-box;
    background-color: #d9d9d9;
    border-radius: 10px;
  }

  .el-select {
    margin: 0 0 20px;
  }

  button.cross {
    position: absolute;
    top: 15px;
    right: 20px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
  }

  div.modal h3 {
    text-transform: uppercase;
    margin: 0 0 25px;
  }

  p {
    text-align: left;
    font-size: 14px;
    margin: 0 0 5px;
  }

  h1 {
    margin: 0 0 10px;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
  }

  div.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
